<template>
  <div>
    <v-row v-if="fromReleasePage && !fromLatest">
      <v-col cols="12">
        <div>
          <div class="mb-12">ID: {{ get(release, "id", "") }}</div>
          <h1 class="text-center mt-2 display-1">
            v{{ get(release, "info.version", "") }}
          </h1>
        </div>
        <div class="d-flex mb-1">
          <div class="row no-gutters">
            <h1 class="col-12 text-center mt-4 mb-6">
              {{ get(release, "info.name", "") }}
            </h1>
            <div class="col-12 mb-6 mx-auto text-center">
              <v-icon
                color="primary"
                v-text="
                  release.platform === 'android' ? 'mdi-android' : 'mdi-apple'
                "
              ></v-icon>
            </div>
            <div class="col-12">
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="text-center mb-4">
                  <div class="font-size-h2 text-muted px-4 py-1">Package</div>
                  <div class="font-size-h4">
                    {{ get(release, "info.package", "") }}
                  </div>
                </v-col>
                <v-col cols="12" sm="6" class="text-center mb-4">
                  <div class="font-size-h2 text-muted px-4 py-1">
                    Version Code
                  </div>
                  <div class="font-size-h4">
                    {{ get(release, "info.version_code", "") }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="col-12">
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="text-center mb-4">
                  <div class="text-muted px-4 py-1 font-size-h2">File Name</div>
                  <div class="font-size-h4">
                    <!-- for more information about this component read the docs -->
                    {{ get(release, "info.uploaded_file_name", "") }}
                  </div>
                </v-col>
                <v-col cols="12" sm="6" class="text-center mb-4">
                  <div class="text-muted px-4 py-1 font-size-h2">Developer</div>
                  <div class="font-size-h4">
                    {{ get(release, "user.name", "") }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-card outlined>
      <v-card-title v-if="!fromLatest">
        <div>
          <div class="font-size-h3 dark-grey-text bold-text">
            App installs
            <VueTooltip
              color="primary"
              custom
              :v-html="`Install log for this ${
                fromReleasePage ? 'release' : 'app'
              } <br /></br />Installs are mostly unique as per browser & device <br /><br />We are updating the log every minute.<br /><br />Please note this feature is in BETA, please report if you think the data is incorrect. <br /><br />We welcome any feedback!`"
            >
              <template>
                <v-btn
                  icon
                  class="font-weight-bold title"
                  color="primary"
                  right
                >
                  ?
                </v-btn>
              </template>
            </VueTooltip>
          </div>
          <div class="text-muted bold-text text-body-1">
            Install log for
            {{ fromReleasePage ? "this release" : "all releases in this app" }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          text
          v-if="!fromReleasePage"
          @click="exportInstallList"
          class="text-transform-none mr-2"
          :loading="busy"
          color="primary"
          depressed
          :disabled="!canManageApp"
        >
          <v-icon v-text="'mdi-file-export'" left></v-icon>
          Export all (.csv)
        </v-btn>
      </v-card-title>
      <v-divider v-if="!fromLatest"></v-divider>
      <v-card-text class="pa-0">
        <v-row>
          <v-col v-if="loading" cols="12">
            <v-skeleton-loader
              class="transparent"
              type="table-thead, table-tbody"
            />
          </v-col>
          <v-col v-else cols="12">
            <v-data-table
              v-if="canManageApp"
              :headers="headers"
              :items="releaseInstallList"
              :page.sync="page"
              :loading="loading"
              :server-items-length="totalCount"
              class="hide-v-data-footer__select"
              mobile-breakpoint="1070"
            >
              <template #item.user="{ item }">
                <div v-if="item.member">
                  <!-- <ProfileImage
                    size="40"
                    :image="get(item, 'member.image', '')"
                    :alt="get(item, 'member.name', '')"
                  /> -->

                  {{ get(item, "member.name", "") }}
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <!-- <v-avatar v-on="on" size="40">
                        <div>?</div>
                      </v-avatar> -->
                      <span v-on="on"> Anonymous</span>
                    </template>
                    <span>
                      <div class="mb-2">
                        Anonymous users can be possible of the following
                        scenarios:
                      </div>
                      <ul>
                        <li>Public app release download</li>
                        <li>Direct release file download</li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
              </template>
              <template #item.device="{ item }">
                <user-agent-parser
                  :full="true"
                  :default-device="item.device"
                ></user-agent-parser>
              </template>
              <template #item.country="{ item }">
                <country-city :detail="item"></country-city>
              </template>
              <template v-if="!fromReleasePage" #item.release="{ item }">
                <div v-if="item.release && item.release.status">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon
                          small
                          left
                          :color="
                            $options.RELEASE_STATUS[item.release.status].color
                          "
                          v-text="
                            item.release.platform === 'android'
                              ? 'mdi-android'
                              : 'mdi-apple'
                          "
                        ></v-icon>
                        v<MaxText
                          :with-tooltip="false"
                          :text="get(item, 'release.version', '')"
                          max="15"
                        />
                      </span>
                    </template>
                    <span>
                      <ul>
                        <li>
                          Status:
                          {{
                            $options.RELEASE_STATUS[item.release.status].title
                          }}
                        </li>
                        <li>
                          Version:
                          {{ item.release.version }}
                        </li>
                        <li>
                          Version Code:
                          {{ item.release.version_code }}
                        </li>
                        <li>
                          Size:
                          {{ formatBytes(item.release.size) }}
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
                <!-- No release selected -->
                <div v-else>-</div>
              </template>
              <template #item.date="{ item }">
                <vue-hoverable-date
                  :date="item.installed_at"
                ></vue-hoverable-date>
              </template>
              <template slot="no-data">
                <no-data
                  first-text="No installs or downloads logged for this release"
                  second-text="Install logs can be useful to trace the devices that installed your release for debugging purposes"
                ></no-data>
              </template>
              <template #footer.page-text>
                <div>
                  <v-btn
                    class="text-transform-none"
                    @click="getReleaseInstallList()"
                    text
                  >
                    <v-icon v-text="'mdi-reload'" color="grey" left> </v-icon>
                    Reload
                  </v-btn>
                  Total: <b>{{ totalCount }}</b>
                </div>
              </template>
            </v-data-table>
            <template v-else>
              <no-data
                first-text="No installs or downloads"
                second-text="You don't have the permission to view the installs or downloads"
              ></no-data>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<style lang="scss">
.empty_icon {
  width: 100px;
}
.member-search {
  border: 1px solid #b5b5c3;
  background-color: #fff !important;
}
.member-panel {
  padding: 10px;
  border: 1px solid rgba(57, 107, 219, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
  img {
    height: 40px;
    width: 40px;
  }
}
</style>

<script>
import {
  getInstallTime,
  get,
  isEmpty,
  formatBytes,
} from "@/core/services/helper.service.js";
import {
  GET_APP_INSTALL_LIST,
  GET_RELEASE_INSTALL_LIST,
} from "@/store/releases/createRelease.module.js";
import { mapGetters, mapMutations } from "vuex";
import { GENERATE_APP_EXPORT } from "@/store/apps/app.module";
import UserAgentParser from "@/view/components/Common/UserAgentParser";
import CountryCity from "@/view/components/Common/CountryCity";

export default {
  name: "release-info",
  props: {
    fromReleasePage: {
      type: Boolean,
      default: false,
    },
    fromLatest: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
    app_id: {
      type: String,
      default: "",
    },
    release: {
      type: [Object, Boolean],
      default() {
        return {};
      },
    },
    app: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { CountryCity, UserAgentParser },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      releaseInfo: null,
      getInstallTime,
      member: "",
      page: 1,
      releaseInstallList: [],
      totalReleaseInstallList: [],
      readMore: true,
      devices: [],
      headers: [
        { text: "User", width: "20%", value: "user", sortable: false },
        { text: "Device", width: "20%", value: "device", sortable: false },
        { text: "Location", width: "10%", value: "country", sortable: false },
        { text: "Release", width: "15%", value: "release", sortable: false },
        { text: "Date", width: "15%", value: "date", sortable: false },
      ],
      search: "",
      loading: false,
      totalMembers: 0,
      totalCount: 0,
      busy: false,
      showDeviceInfo: false,
      memberList: [],
    };
  },
  RELEASE_STATUS: {
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "primary" },
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalReleaseInstallList.length > oldVal * 10) {
          this.releaseInstallList = this.totalReleaseInstallList.slice(
            offset,
            limit
          );
        } else {
          // if it is first time, get last id and hit api
          const lastInstalledAt =
            this.releaseInstallList[this.releaseInstallList.length - 1]
              .installed_at;
          this.getReleaseInstallList(lastInstalledAt);
        }
      } else {
        // if it is previous then show already fetched data
        this.releaseInstallList = this.totalReleaseInstallList.slice(
          offset,
          limit
        );
      }
    },
  },
  created() {
    if (this.fromReleasePage) {
      window.analytics.track("Opened installs logs", {
        app_id: this.$route.params.app_id,
        releaes_id: this.release.id,
      });
      this.headers = this.headers.filter((header) => header.text !== "Release");
    }
  },
  computed: {
    ...mapGetters({
      getAndroidLatestInstallData: "getAndroidLatestInstallData",
      getIosLatestInstallData: "getIosLatestInstallData",
    }),
    filteredMember() {
      return this.memberList.filter((member) =>
        member.member.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    installLink() {
      return `${location.origin}/install/${this.$route.params.app_id}/${this.release.id}`;
    },
  },
  mounted() {
    if (this.fromLatest && this.platform === "android") {
      if (
        this.getAndroidLatestInstallData &&
        this.getAndroidLatestInstallData
      ) {
        this.releaseInstallList = [...this.getAndroidLatestInstallData];
        this.totalReleaseInstallList = [...this.getAndroidLatestInstallData];
        this.totalCount = this.getAndroidLatestInstallData.length;
      }
    }
    if (this.fromLatest && this.platform === "ios") {
      if (this.getIosLatestInstallData && this.getIosLatestInstallData) {
        this.releaseInstallList = this.getIosLatestInstallData;
        this.totalReleaseInstallList = this.getIosLatestInstallData;
        this.totalCount = this.getIosLatestInstallData.length;
      }
    }
    if (
      (this.fromLatest &&
        this.platform === "ios" &&
        this.getIosLatestInstallData) ||
      (this.platform === "android" && this.getAndroidLatestInstallData)
    ) {
      return;
    } else {
      if (this.canManageApp) {
        this.getReleaseInstallList();
      }
    }
  },
  methods: {
    ...mapMutations({
      setAndroidLatestInstallData: "setAndroidLatestInstallData",
      setIosLatestInstallData: "setIosLatestInstallData",
    }),
    exportInstallList() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GENERATE_APP_EXPORT, {
          app_id: this.$route.params.app_id,
          type: "installs",
        })
        .then((response) => {
          this.notifyUserMessage(response.message);
          if (response.file) {
            window.open(response.file);
          }
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    getReleaseInstallList(lastInstalledAt) {
      if (!lastInstalledAt) {
        this.page = 1;
      }
      if (this.loading) return;
      this.loading = true;
      const endpoint = this.fromReleasePage
        ? GET_RELEASE_INSTALL_LIST
        : GET_APP_INSTALL_LIST;
      this.$store
        .dispatch(endpoint, {
          release_id: this.release.id,
          app_id: this.$route.params.app_id,
          lastInstalledAt: lastInstalledAt,
        })
        .then((response) => {
          this.loading = false;
          if (lastInstalledAt) {
            this.totalReleaseInstallList = [
              ...this.totalReleaseInstallList,
              ...response.data[0].data,
            ];
            this.releaseInstallList = response.data[0].data;
          } else {
            this.releaseInstallList = response.data[0].data;
            this.totalCount = response.data[0].meta.total;
            this.totalReleaseInstallList = response.data[0].data;
          }
          if (this.platform === "ios" && !this.getIosLatestInstallData) {
            this.setIosLatestInstallData(response.data[0].data);
          }
          if (
            this.platform === "android" &&
            !this.getAndroidLatestInstallData
          ) {
            this.setAndroidLatestInstallData(response.data[0].data);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
