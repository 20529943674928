var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[(!_vm.fromLatest)?_c('v-card-title',[_c('div',[_c('div',{staticClass:"font-size-h3 dark-grey-text bold-text"},[_vm._v(" App installs exports ")]),_c('div',{staticClass:"text-muted bold-text text-body-1"},[_vm._v(" All exported app installs ")])]),_c('v-spacer')],1):_vm._e(),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[(_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"transparent",attrs:{"type":"table-thead, table-tbody"}})],1):_c('v-col',{attrs:{"cols":"12"}},[(_vm.canManageApp)?_c('v-data-table',{staticClass:"hide-v-data-footer__select",attrs:{"headers":_vm.headers,"items":_vm.releaseInstallList,"page":_vm.page,"loading":_vm.loading,"server-items-length":_vm.totalCount,"mobile-breakpoint":"1070"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('div',[_c('ProfileImage',{attrs:{"size":"40","image":_vm.get(item, 'user.image', ''),"alt":_vm.get(item, 'user.name', '')}}),_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.get(item, "user.name", ""))+" ")])],1):_vm._e()]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.status !== 1,"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadFile(item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',[_vm._v(" Download file ")])])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatBytes(item.file_size / 1000 || 0))+" ")]}},{key:"item.expire",fn:function(ref){
var item = ref.item;
return [_c('vue-hoverable-date',{attrs:{"date":item.expire_at}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('vue-hoverable-date',{attrs:{"date":item.created_at}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$options.STATUS[item.status].color + '--text'},[_vm._v(" "+_vm._s(_vm.$options.STATUS[item.status].title)+" ")])]}},{key:"footer.page-text",fn:function(){return [_c('v-btn',{staticClass:"text-transform-none",attrs:{"text":""},on:{"click":function($event){return _vm.getReleaseInstallList()}}},[_c('v-icon',{attrs:{"color":"grey","left":""},domProps:{"textContent":_vm._s('mdi-reload')}}),_vm._v(" Reload ")],1),_vm._v(" Total: "),_c('b',[_vm._v(_vm._s(_vm.totalCount))])]},proxy:true}],null,false,2651707316)},[_c('template',{slot:"no-data"},[_c('no-data',{attrs:{"first-text":"No exports done for this install","second-text":"Install logs can be useful to trace the devices that installed your release for debugging purposes","permission-issues":""}})],1)],2):_c('no-data',{attrs:{"first-text":"No app installs exports","second-text":"You don't have the permission to view or manage the app exports","permission-issues":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }