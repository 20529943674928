<template>
  <v-row>
    <v-col cols="12">
      <v-tabs>
        <v-tab>Install</v-tab>
        <v-tab>Exports</v-tab>
        <v-tab-item>
          <install-list
            :info="appInfo"
            :loader="loading"
            from-app
            :app_id="appInfo.id"
            :infoError="errorMessages"
          ></install-list>
        </v-tab-item>
        <v-tab-item>
          <install-exports></install-exports>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import InstallList from "@/view/components/App/Dashboard/InstallList.vue";
import InstallExports from "@/view/components/App/Dashboard/InstallExports";
import { mapGetters } from "vuex";

export default {
  components: { InstallList, InstallExports },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
    }),
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped>
</style>
