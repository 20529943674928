<template>
  <div>
    <v-card outlined>
      <v-card-title v-if="!fromLatest">
        <div>
          <div class="font-size-h3 dark-grey-text bold-text">
            App installs exports
            <!--            <VueTooltip-->
            <!--              color="primary"-->
            <!--              custom-->
            <!--              :v-html="-->
            <!--                `Install log for this ${-->
            <!--                  fromReleasePage ? 'release' : 'app'-->
            <!--                } <br /></br />We are updating this info every minute.<br /><br /> Please note this feature is in BETA, please report if you think the data is incorrect. <br /><br />We welcome any feedback!`-->
            <!--              "-->
            <!--              :max-width="200"-->
            <!--            >-->
            <!--              <template>-->
            <!--                <v-btn-->
            <!--                  icon-->
            <!--                  class="font-weight-bold title"-->
            <!--                  color="primary"-->
            <!--                  right-->
            <!--                >-->
            <!--                  ?-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--            </VueTooltip>-->
          </div>
          <div class="text-muted bold-text text-body-1">
            All exported app installs
          </div>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-row>
          <v-col v-if="loading" cols="12">
            <v-skeleton-loader
              class="transparent"
              type="table-thead, table-tbody"
            />
          </v-col>
          <v-col v-else cols="12">
            <v-data-table
              v-if="canManageApp"
              :headers="headers"
              :items="releaseInstallList"
              :page.sync="page"
              :loading="loading"
              :server-items-length="totalCount"
              class="hide-v-data-footer__select"
              mobile-breakpoint="1070"
            >
              <template #item.user="{ item }">
                <div v-if="item.user">
                  <ProfileImage
                    size="40"
                    :image="get(item, 'user.image', '')"
                    :alt="get(item, 'user.name', '')"
                  />
                  <span class="ml-4">
                    {{ get(item, "user.name", "") }}
                  </span>
                </div>
              </template>
              <template #item.file="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="downloadFile(item)"
                      :disabled="item.status !== 1"
                      icon
                      color="primary"
                    >
                      <v-icon v-text="'mdi-download'"></v-icon>
                    </v-btn>
                  </template>
                  <span> Download file </span>
                </v-tooltip>
              </template>
              <template #item.size="{ item }">
                {{ formatBytes(item.file_size / 1000 || 0) }}
              </template>
              <template #item.expire="{ item }">
                <vue-hoverable-date :date="item.expire_at"></vue-hoverable-date>
              </template>
              <template #item.date="{ item }">
                <vue-hoverable-date
                  :date="item.created_at"
                ></vue-hoverable-date>
              </template>
              <template #item.status="{ item }">
                <div :class="$options.STATUS[item.status].color + '--text'">
                  {{ $options.STATUS[item.status].title }}
                </div>
              </template>
              <template slot="no-data">
                <no-data
                  first-text="No exports done for this install"
                  second-text="Install logs can be useful to trace the devices that installed your release for debugging purposes"
                  permission-issues
                ></no-data>
              </template>
              <template #footer.page-text>
                <v-btn
                  class="text-transform-none"
                  @click="getReleaseInstallList()"
                  text
                >
                  <v-icon v-text="'mdi-reload'" color="grey" left> </v-icon>
                  Reload
                </v-btn>
                Total: <b>{{ totalCount }}</b>
              </template>
            </v-data-table>

            <no-data
              v-else
              first-text="No app installs exports"
              second-text="You don't have the permission to view or manage the app exports"
              permission-issues
            ></no-data>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  getInstallTime,
  get,
  isEmpty,
  formatBytes,
} from "@/core/services/helper.service.js";
import { APP_EXPORT_LIST } from "@/store/apps/app.module";

export default {
  name: "install-export",
  props: {
    fromReleasePage: {
      type: Boolean,
      default: false,
    },
    fromLatest: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
    app_id: {
      type: String,
      default: "",
    },
    release: {
      type: [Object, Boolean],
      default() {
        return {};
      },
    },
    app: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  STATUS: {
    1: {
      title: "Active",
      color: "success",
    },
    2: {
      title: "Processing",
      color: "orange",
    },
    3: {
      title: "Expired",
      color: "error",
    },
  },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      releaseInfo: null,
      getInstallTime,
      member: "",
      page: 1,
      releaseInstallList: [],
      totalReleaseInstallList: [],
      readMore: true,
      devices: [],
      headers: [
        { text: "Requester", width: "20%", value: "user", sortable: false },
        {
          text: "File",
          width: "15%",
          align: "start",
          value: "file",
          sortable: false,
        },
        { text: "Size", width: "10%", value: "size", sortable: false },
        { text: "Expire", width: "10%", value: "expire", sortable: false },
        { text: "Generated", width: "10%", value: "date", sortable: false },
        { text: "Status", width: "5%", value: "status", sortable: false },
      ],
      search: "",
      loading: false,
      totalMembers: 0,
      totalCount: 0,
      busy: false,
      showDeviceInfo: false,
      memberList: [],
    };
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalReleaseInstallList.length > oldVal * 10) {
          this.releaseInstallList = this.totalReleaseInstallList.slice(
            offset,
            limit
          );
        } else {
          // if it is first time, get last id and hit api
          const lastInstalledAt =
            this.releaseInstallList[this.releaseInstallList.length - 1].id;
          this.getReleaseInstallList(lastInstalledAt);
        }
      } else {
        // if it is previous then show already fetched data
        this.releaseInstallList = this.totalReleaseInstallList.slice(
          offset,
          limit
        );
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    if (this.canManageApp) {
      this.getReleaseInstallList();
    }
  },
  methods: {
    getReleaseInstallList(lastId) {
      if (!lastId) {
        this.page = 1;
      }
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(APP_EXPORT_LIST, {
          app_id: this.$route.params.app_id,
          lastId: lastId,
          type: "installs",
        })
        .then((response) => {
          this.loading = false;
          if (lastId) {
            this.totalReleaseInstallList = [
              ...this.totalReleaseInstallList,
              ...response.data[0].data,
            ];
            this.releaseInstallList = response.data[0].data;
          } else {
            this.releaseInstallList = response.data[0].data;
            this.totalCount = response.data[0].meta.total;
            this.totalReleaseInstallList = response.data[0].data;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
        });
    },
    downloadFile(item) {
      window.open(item.file_url);
    },
  },
};
</script>
